/*--- Main navigation ---*/

$('.navigation-list--main').mouseover(function () {
    event.stopPropagation();
    
    let subNav = $(this).children('.navigation-sub');
    let navLists = document.getElementsByClassName('navigation-list--main');
    let self = this;

    $('.navigation-list--main').each(function () {
        if (self.id !== this.id) {
            $(this).removeClass('is-active');
            $(this).children('.navigation-sub').removeClass('is-active');
        }
    });

    if (subNav && !$(subNav).hasClass('is-active')) {
        $(this).addClass('is-active');
        $(subNav).addClass('is-active');
    }
});

$('.navigation-wrapper, .navigation-sub').mouseleave(function (e) {
    if (!$(e.currentTarget).hasClass('navigation-sub') && !$(e.currentTarget).hasClass('navigation-list--main')) {
        if ($('.navigation-sub').hasClass('is-active') && $('.navigation-list--main').hasClass('is-active')) {
            $('.navigation-sub').removeClass('is-active');
            $('.navigation-sub').parent().removeClass('is-active');
        }
    }
});

/*--- Mobile navigation ---*/

$('.js--menu').click(function () {
    let navList = $('.navigation-list');

    navList.toggleClass('is-active');
    $(this).toggleClass('is-active');
});

/*$('.navigation-link--main').click(function () {
    event.preventDefault();
    
    let subNav = $(this).siblings('.navigation-sub');

    if ($(this).hasClass('navigation-link--main') && !$(this).parent().hasClass('is-active')) {
        $(this).parent().toggleClass('is-active');
        $(subNav).toggleClass('is-active');
    }
});*/

/*--- Tile with background image ---*/

$('.background-img, .tile-with-background').mouseover(function (e) {
    if ($(e.currentTarget).hasClass('background-img')) {
        $(e.currentTarget).addClass('is-active');
    } else if ($(e.currentTarget).hasClass('tile-with-background')) {
        $(e.currentTarget.previousElementSibling).addClass('is-active');
    }
});

$('.background-img, .tile-with-background').mouseleave(function (e) {
    if ($(e.currentTarget).hasClass('background-img')) {
        $(e.currentTarget).removeClass('is-active');
    } else if ($(e.currentTarget).hasClass('tile-with-background')) {
        $(e.currentTarget.previousElementSibling).removeClass('is-active');
    }
});

/*--- Search bar navigation ---*/

$('.nav-search--icon').click(function (e) {
    let input = $('.nav-search--input');

    if (input.hasClass('is-active')) {
        input.removeClass('is-active');
        $('#form-search-page').submit();
    } else {
        input.addClass('is-active');
        input.focus();
    }
});


// initialize plugin for table of contents scroll
const content = document.querySelector('.scroll-content');
scrollnav.init(content, {sections: 'h1.scroll-h1,h2,p.scroll-h1,p.scroll-h2,p.scroll-h3'});


/*$('.post-article').scrollNav({
  sections: 'h3',
  sectionElem: 'div',
  insertTarget: '.leftpane',
  insertLocation: 'appendTo'
});*/

$('.scroll-nav').addClass('scroll-nav--faded');

$(window).scroll(function () {
    let scrollTop = $(this).scrollTop();

    if (scrollTop >= 200 && scrollTop < $('.scroll-content').height()) {
        $('.scroll-nav').removeClass('scroll-nav--faded');
    } else {
        $('.scroll-nav').addClass('scroll-nav--faded');
    }
});

jQuery(document).ready(function($) {
    $('.popup-video').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });
});

